import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

export const ChatOnWhatsApp = ({ message }) => {
  const phoneNumber = '+4799866744';

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <button
      onClick={handleClick}
      className="fixed bottom-4 right-4 bg-green-500 hover:bg-green-600 text-white p-3 rounded-full shadow-lg flex items-center justify-center z-10"
      aria-label="Chat on WhatsApp"
    >
      <FaWhatsapp className="h-6 w-6" />
    </button>
  );
};
