import logo from 'assets/logo.png';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link } from 'react-router-dom';
import { Team } from './Team';
import { WhyChooseUs } from './WhyChooseUs';
import { usePageInfo } from '../api/getPageInfo';
import { FullScreenSpinner, MDPreview } from 'components/Elements';
import { useAboutInfo } from '../api/getAboutInfo';
import { useEffect } from 'react';

export const AboutUs = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const pageInfoQuery = usePageInfo();
  const aboutInfoQuery = useAboutInfo();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (pageInfoQuery.isLoading || aboutInfoQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!pageInfoQuery.data || !aboutInfoQuery.data) return null;

  return (
    <WebLayout>
      <Head title="About Us" description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">About Us</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-3xl mb-3">About Us</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="mx-4 md:mx-48 my-3 text-center mb-8">{pageInfoQuery.data?.about?.header}</div>
      <div className="mx-4 md:mx-48">
        <div className="mx-4 header mb-3 flex items-center">
          <div className="text-4xl font-semibold">Mission</div>
          <div className="flex items-center ml-4 md:ml-8">
            <div className="bg-black rounded-full h-1 w-1 md:h-4 md:w-4"></div>
            <div className="bg-black rounded-full h-2 w-2 md:h-8 md:w-8 ml-2 md:ml-4"></div>
          </div>
        </div>
        <div className="md:w-1/2">
          <MDPreview value={aboutInfoQuery.data?.mission} />
        </div>
      </div>
      <div className="mt-8 mx-4 md:mx-48 flex flex-col justify-end items-end mb-8">
        <div className="mx-4 header mb-3 flex flex-row justify-start items-start">
          <div className="text-4xl font-semibold">Vision</div>
          <div className="flex items-center ml-4 md:ml-8">
            <div className="bg-black rounded-full h-1 w-1 md:h-4 md:w-4"></div>
            <div className="bg-black rounded-full h-2 w-2 md:h-8 md:w-8 ml-2 md:ml-4"></div>
          </div>
        </div>
        <div className="md:w-1/2">
          <MDPreview value={aboutInfoQuery.data?.vision} />
        </div>
      </div>
      <Team />
      <WhyChooseUs content={aboutInfoQuery.data?.why_choose_us} />
    </WebLayout>
  );
};
