import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getAboutInfo = () => {
  return axios.get('/about/info');
};

export const useAboutInfo = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['aboutInfo'],
    queryFn: () => getAboutInfo(),
  });
};
