import clsx from 'clsx';
import PropTypes from 'prop-types';

const sizes = {
  sm: 'h-4 w-4',
  md: 'h-8 w-8',
  lg: 'h-16 w-16',
  xl: 'h-24 w-24',
};

const variants = {
  light: 'text-white',
  primary: 'text-[#142852]',
};

export const Spinner = ({ size = 'md', variant = 'primary', className = '' }) => {
  return (
    <>
      <svg
        className={clsx(sizes[size], variants[variant], className)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        data-testid="loading"
      >
        {/* Roof */}
        <polygon
          className="stroke-current"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          points="12,2 22,12 2,12"
          strokeDasharray="30"
          strokeDashoffset="0"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="30"
            to="0"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </polygon>
        {/* Body */}
        <rect
          className="stroke-current"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          x="6"
          y="12"
          width="12"
          height="10"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="40"
            to="0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="0.5s"
          />
        </rect>
        {/* Door */}
        <rect
          className="stroke-current"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          x="10"
          y="14"
          width="4"
          height="8"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="16"
            to="0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="1s"
          />
        </rect>
      </svg>
      <span className="sr-only">Loading</span>
    </>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  variant: PropTypes.oneOf(['light', 'primary']),
  className: PropTypes.string,
};
