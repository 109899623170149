import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link, useParams } from 'react-router-dom';
import { useDesign } from '../api/getDesign';
import { useEffect } from 'react';

export const ConfirmPayment = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { slug, orderId } = useParams();

  const designQuery = useDesign({ slug });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      <Head title={designQuery.data?.title} description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48 text-xs md:text-sm">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <Link to="/designs">Designs</Link>
          <span className="mx-2">/</span>
          <Link to={`/designs/${designQuery.data?.slug}`}>{designQuery.data?.title}</Link>
          <span className="mx-2">/</span>
          <span className="">Order Now</span>
          <span className="mx-2">/</span>
          <span className="font-bold">Pay</span>
          <span className="mx-2">/</span>
          <span className="font-bold">Confirm Payment</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-center text-3xl mb-3">Confirm Payment</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="mx-8 md:mx-48 mb-4"></div>
    </WebLayout>
  );
};
