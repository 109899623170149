import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getPageInfo = () => {
  return axios.get('/pages/info');
};

export const usePageInfo = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['pageInfo'],
    queryFn: () => getPageInfo(),
  });
};
