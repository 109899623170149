import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link, useParams } from 'react-router-dom';
import { useDesign } from '../api/getDesign';
import { UPLOADS_URL } from 'config';
import { useEffect } from 'react';
import { UpdateShippingDetailsForm } from '../components/UpdateShippingDetailsForm';

export const Order = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { slug } = useParams();

  const designQuery = useDesign({ slug });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      <Head title={designQuery.data?.title} description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48 text-xs md:text-sm">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <Link to="/designs">Designs</Link>
          <span className="mx-2">/</span>
          <Link to={`/designs/${designQuery.data?.slug}`}>{designQuery.data?.title}</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">Order Now</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-center text-3xl mb-3">Order Now</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="mx-8 md:mx-48 mb-4">
        <div className="font-semibold text-lg mb-4">Order Summary</div>
        <div className="bg-[#FBFBFB] p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <img
              src={UPLOADS_URL + '/designs/' + designQuery.data?.images[0]}
              alt="design"
              className="w-full h-80 object-cover"
            />
            <div className="">
              <div className="font-semibold text-lg mb-4">{designQuery.data?.title}</div>
              <div className=" text-lg mb-4">{designQuery.data?.description}</div>
              <div className=" text-lg mb-4">
                Budget: UGX {parseInt(designQuery.data?.price)?.toLocaleString()}
              </div>
              <div className="">Customization Options</div>
              <div className="text-sm">
                <ul>
                  {designQuery.data?.customization_options?.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdateShippingDetailsForm />
    </WebLayout>
  );
};
