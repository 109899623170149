import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getTeam = () => {
  return axios.get('/teams/all');
};

export const useTeam = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['team'],
    queryFn: () => getTeam(),
  });
};
