import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { RecentDesigns } from './RecentDesigns';
import { Feedback } from './Feedback';
import { Link } from 'react-router-dom';
import { usePageInfo } from '../api/getPageInfo';
import { FullScreenSpinner, MDPreview } from 'components/Elements';
import { UPLOADS_URL } from 'config';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const pageInfoQuery = usePageInfo();

  if (pageInfoQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!pageInfoQuery.data) return null;

  return (
    <WebLayout>
      <Head title="Verdens" description="Design Group" />
      <div
        id="banner"
        className="relative py-8 md:py-24 p-8 flex flex-col space-y-5 justify-center items-center"
      >
        <div className="absolute left-4 top-24 md:left-12 md:top-24 z-0 opacity-30">
          <img
            src={UPLOADS_URL + '/pages/' + pageInfoQuery.data?.home?.images?.image_a}
            alt="Image A"
            className="w-40 h-40 md:w-[30vw] md:h-[30vw] rounded-full"
          />
        </div>
        <div className="absolute left-24 top-36 md:left-48 md:top-48 z-0 opacity-30">
          <img
            src={UPLOADS_URL + '/pages/' + pageInfoQuery.data?.home?.images?.image_b}
            alt="Image B"
            className="w-40 h-40 md:w-[30vw] md:h-[30vw] rounded-full"
          />
        </div>
        <div className="absolute right-4 top-12 md:right-12 md:top-24 z-0 opacity-30">
          <img
            src={UPLOADS_URL + '/pages/' + pageInfoQuery.data?.home?.images?.image_c}
            alt="Image C"
            className="w-40 h-40 md:w-[30vw] md:h-[30vw] rounded-full"
          />
        </div>
        <div className="relative z-10 text-center">
          <div className="text-2xl md:text-7xl font-semibold">
            {pageInfoQuery.data?.home?.banner?.top_content}
          </div>
          <div className="text-xl md:text-5xl font-semibold">
            {pageInfoQuery.data?.home?.banner?.bottom_content}
          </div>
        </div>
        <div className="relative z-10 text-md md:text-lg md:w-1/2 text-center">
          <MDPreview value={pageInfoQuery.data?.home?.about} />
        </div>
        <div className="relative z-10 flex flex-row items-center">
          <Link
            to="/projects/start-a-project"
            className="bg-[#142852] p-3 px-4 uppercase text-white"
          >
            Start a project
          </Link>
          <div className="rounded-full border border-[#142852] p-3 ml-1 flex items-center justify-center">
            <ChevronRight className="text-[#142852]" />
          </div>
        </div>
      </div>

      <RecentDesigns />
      <Feedback />
    </WebLayout>
  );
};
