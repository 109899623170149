import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <Layout
      title="Register new account"
      caption="Register a new account and start browsing through our designs or you can order for a custom design."
    >
      <RegisterForm onSuccess={() => navigate('/')} />
    </Layout>
  );
};
