import React from 'react';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { ChatOnWhatsApp } from './components/ChatOnWhatsApp';

export const WebLayout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <ChatOnWhatsApp message="Hello, I would like to chat with you." />
      {children}
      <Footer />
    </div>
  );
};
