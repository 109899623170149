import logo from 'assets/logo.png';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export const CookieNotice = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      <Head title="Cookie Notice" description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">Cookie Notice</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-3xl mb-3">Cookie Notice</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="px-4 md:px-48 text-left">
        <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-4">
          At Verdens, we use cookies to enhance your browsing experience, understand website usage,
          and assist in our marketing efforts. This Cookie Notice explains what cookies are, how we
          use them, and your choices regarding their usage.
        </p>
        <h2 className="text-xl font-semibold mb-4">2. What are Cookies?</h2>
        <p className="mb-4">
          Cookies are small text files stored on your device (computer, tablet, or mobile) when you
          visit a website. They help the website recognize your device and store some information
          about your preferences or past actions.
        </p>
        <h2 className="text-xl font-semibold mb-4">3. Types of Cookies We Use</h2>
        <p className="mb-4">
          <strong>Essential Cookies:</strong> These cookies are necessary for the website to
          function and cannot be switched off. They are usually set in response to actions made by
          you, such as setting your privacy preferences, logging in, or filling in forms.
        </p>
        <p className="mb-4">
          <strong>Performance and Analytics Cookies:</strong> These cookies help us understand how
          visitors interact with our website by collecting and reporting information anonymously.
        </p>
        <p className="mb-4">
          <strong>Functional Cookies:</strong> These cookies enable the website to provide enhanced
          functionality and personalization. They may be set by us or by third-party providers whose
          services we have added to our pages.
        </p>
        <p className="mb-4">
          <strong>Targeting Cookies:</strong> These cookies may be set through our site by our
          advertising partners. They may be used by those companies to build a profile of your
          interests and show you relevant adverts on other sites. They do not store directly
          personal information but are based on uniquely identifying your browser and internet
          device.
        </p>
        <h2 className="text-xl font-semibold mb-4">4. Managing Cookies</h2>
        <p className="mb-4">
          You can manage your cookie preferences through your browser settings. Most browsers allow
          you to refuse or accept cookies. The methods for doing so vary from browser to browser,
          and from version to version. You can obtain up-to-date information about blocking and
          deleting cookies via these links:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/kb/PH21411"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari
            </a>
          </li>
          <li>
            <a
              href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Edge
            </a>
          </li>
        </ul>
        <p className="mb-4">
          Please note that blocking all cookies will have a negative impact upon the usability of
          many websites. If you block cookies, you may not be able to use all the features on our
          website.
        </p>
        <h2 className="text-xl font-semibold mb-4">5. Changes to This Cookie Notice</h2>
        <p className="mb-4">
          We may update this Cookie Notice from time to time in order to reflect, for example,
          changes to the cookies we use or for other operational, legal, or regulatory reasons.
          Please re-visit this Cookie Notice regularly to stay informed about our use of cookies and
          related technologies.
        </p>
        <h2 className="text-xl font-semibold mb-4">6. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about our use of cookies or other technologies, please email us
          at support@verdens.ug.
        </p>
      </div>
    </WebLayout>
  );
};
