import React from 'react';
import { Facebook, Instagram, Linkedin } from 'react-feather';
import { Link } from 'react-router-dom';
import { useSocials } from './api/getSocials';

export const Footer = () => {
  const socialsQuery = useSocials();

  if (socialsQuery.isLoading) {
    return <> </>;
  }

  return (
    <footer className="bg-[#171717] py-8 px-12 md:px-48">
      <div className="container mx-auto mt-8 text-center text-white">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex flex-col items-start">
            <div className="flex flex-col items-start mb-5">
              <span className="font-bold uppercase">Need Help?</span>
              <br />
              <Link to="/contact-us">Contact Us</Link>
            </div>
            <div className="flex flex-col items-start">
              <span className="font-bold uppercase">Helpful links</span>
              <br />
              <Link to="/projects/start-a-project">Start a project</Link>
              {/* <br /> */}
              {/* <Link to="">Place an order</Link> */}
            </div>
          </div>
          <div className="flex flex-col items-start">
            <span className="font-bold uppercase">ABOUT VERDENS DESIGN GROUP</span>
            <br />
            <Link to="/about-us">About Us</Link>
            <br />
            <Link to="/careers">Careers</Link>
            <br />
            <Link to="/terms-and-conditions">Terms and conditions</Link>
            <br />
            <Link to="/cookie-notice">Cookie notice</Link>
          </div>
          <div className="">
            <div className="flex flex-col items-start">
              <span className="font-bold uppercase">Follow Us</span>
              <br />
              <div className="flex flex-row space-x-2 mt-2">
                <a
                  href={`https://www.facebook.com/${socialsQuery.data?.facebook}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook className="text-white" />
                </a>
                <a
                  href={`https://www.instagram.com/${socialsQuery.data?.instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram className="text-white" />
                </a>
                <a
                  href={`https://www.linkedin.com/${socialsQuery.data?.linkedin}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin className="text-white" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <p className="text-xm mt-12">&copy; All rights reserved. Verdens Design Group. 2024</p>
      </div>
    </footer>
  );
};
