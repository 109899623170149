import logo from 'assets/logo.png';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export const TermsAndConditions = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      <Head title="Terms And Conditions" description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">Terms And Conditions</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-3xl mb-3">Terms And Conditions</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="px-4 md:px-48 text-left">
        <h2 className="text-xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-4">
          Welcome to Verdens! These terms and conditions outline the rules and regulations for the
          use of Verdens' website, located at www.verdens.ug. By accessing this website, we assume
          you accept these terms and conditions in full. Do not continue to use Verdens' website if
          you do not accept all of the terms and conditions stated on this page.
        </p>
        <h2 className="text-xl font-semibold mb-4">2. Definitions</h2>
        <p className="mb-4">
          <strong>Client</strong>: Refers to the individual accessing this website and accepting the
          company's terms and conditions.
          <br />
          <strong>Company</strong>: Refers to Verdens.
          <br />
          <strong>Services</strong>: Refers to the services offered by Verdens, including
          architectural design search, orders, custom requests, and tracking.
        </p>
        <h2 className="text-xl font-semibold mb-4">3. User Accounts</h2>
        <p className="mb-4">
          To use certain features of the platform, you must register for an account. You agree to
          provide accurate and complete information during the registration process and to update
          such information as needed to keep it accurate and complete. You are responsible for
          safeguarding your account information and agree to notify us immediately of any
          unauthorized use of your account.
        </p>
        <h2 className="text-xl font-semibold mb-4">4. Services</h2>
        <p className="mb-4">
          Verdens offers a platform where users can search for architectural designs, place orders,
          request custom designs, and track their designs. While we strive to provide the best
          service possible, we do not guarantee that our services will be uninterrupted or
          error-free. We reserve the right to modify or discontinue our services at any time without
          prior notice.
        </p>
        <h2 className="text-xl font-semibold mb-4">5. Payment</h2>
        <p className="mb-4">
          Users may be required to provide payment information (e.g., credit card, bank account) to
          purchase designs or request custom services. By providing payment information, you
          represent and warrant that you are authorized to use the designated payment method and
          authorize us to charge the full amount to the payment method you designate. All payments
          are non-refundable, except as required by law.
        </p>
        <h2 className="text-xl font-semibold mb-4">6. Intellectual Property</h2>
        <p className="mb-4">
          All content on this website, including text, graphics, logos, and images, is the property
          of Verdens or its content suppliers and is protected by international copyright laws. You
          agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the
          website without our express written permission.
        </p>
        <h2 className="text-xl font-semibold mb-4">7. Privacy</h2>
        <p className="mb-4">
          Your privacy is important to us. Please review our Privacy Policy, which explains how we
          collect, use, and disclose information about you.
        </p>
        <h2 className="text-xl font-semibold mb-4">8. Limitation of Liability</h2>
        <p className="mb-4">
          In no event shall Verdens be liable for any indirect, incidental, special, consequential,
          or punitive damages arising out of or in connection with your use of the website or
          services, whether based on warranty, contract, tort (including negligence), or any other
          legal theory.
        </p>
        <h2 className="text-xl font-semibold mb-4">9. Termination</h2>
        <p className="mb-4">
          We may terminate or suspend your access to our services immediately, without prior notice
          or liability, for any reason whatsoever, including without limitation if you breach these
          terms and conditions. Upon termination, your right to use the services will immediately
          cease.
        </p>
        <h2 className="text-xl font-semibold mb-4">10. Changes to Terms</h2>
        <p className="mb-4">
          We reserve the right, at our sole discretion, to modify or replace these terms at any
          time. If a revision is material, we will provide at least 30 days' notice prior to any new
          terms taking effect. What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <h2 className="text-xl font-semibold mb-4">11. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about these Terms and Conditions, please contact us at{' '}
          <a href="mailto:support@verdens.ug" className="text-blue-600">
            support@verdens.ug
          </a>
          .
        </p>
      </div>
    </WebLayout>
  );
};
