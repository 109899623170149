// src/components/ImageGallery.js
import { UPLOADS_URL } from 'config';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export const Gallery = ({ images }) => {
  const items = images.map((image) => ({
    original: `${UPLOADS_URL}/designs/${image}`,
    thumbnail: `${UPLOADS_URL}/designs/${image}`,
  }));

  return <ImageGallery items={items} />;
};
