import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Logo } from './Logo';
import { MdClose } from 'react-icons/md';
import { Menu } from 'react-feather';
import { Button } from 'components/Elements';
import { useAuth } from 'lib/auth';
import { UserNavigation } from './UserNavigation';

const LinkItems = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'About Us',
    link: '/about-us',
  },
  {
    title: 'Projects',
    link: '/projects',
  },
  {
    title: 'Designs',
    link: '/designs',
  },
  {
    title: 'Contact Us',
    link: '/contact-us',
  },
];

const LinkItem = ({ title, link }) => {
  return (
    <Link
      to={link}
      className="my-1 text-md text-gray-700 font-medium hover:text-indigo-500 md:mx-4 md:my-0"
    >
      {title}
    </Link>
  );
};

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const { user } = useAuth();

  return (
    <nav className="bg-white px-3 md:px-48 py-3 md:py-5">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex justify-between space-x-48 items-center">
          <div>
            <Logo />
          </div>
          <div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none md:hidden"
            >
              {isOpen ? (
                <MdClose className="h-6 w-6 fill-current" />
              ) : (
                <Menu className="h-6 w-6 fill-current" />
              )}
            </button>
          </div>
        </div>

        <div className={`md:flex items-center ${isOpen ? '' : 'hidden'}`}>
          <div className="flex flex-col items-center md:space-x-6 md:flex-row md:mx-6">
            {LinkItems.map((item, index) => (
              <LinkItem key={index} title={item.title} link={item.link} />
            ))}
            {user ? (
              <UserNavigation />
            ) : (
              <div className="flex flex-col items-center md:space-x-10 md:flex-row md:mx-6">
                <button
                  onClick={() => navigate('/auth/login')}
                  variant="secondary"
                  className="whitespace-nowrap uppercase bg-[#142852] p-2 text-white px-4"
                >
                  GO TO ACCOUNT
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
