import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const createOrder = ({ data }) => {
  return axios.post(`/orders`, data);
};

createOrder.propTypes = {
  data: PropTypes.shape({
    designId: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreateOrder = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newOrder) => {
      await queryClient.cancelQueries('orders');

      const previousOrders = queryClient.getQueryData('orders');

      queryClient.setQueryData('orders', [...(previousOrders || []), newOrder.data]);

      return { previousOrders };
    },
    onError: (_, __, context) => {
      if (context?.previousOrders) {
        queryClient.setQueryData('orders', context.previousOrders);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('orders');
      addNotification({
        type: 'success',
        title: 'Order Created',
      });
    },
    ...config,
    mutationFn: createOrder,
  });
};
