import clsx from 'clsx';

export const CheckboxField = (props) => {
  const { label, disabled, value, caption, registration, onChange, error } = props;
  return (
    <div className="flex justify-start p-4">
      <div>
        <div className="form-check">
          <input
            className="form-check-input appearance-none h-4 w-4 border border-[#142852] rounded-sm bg-white checked:bg-[#142852] checked:border-[#142852] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            disabled={disabled}
            value={value}
            {...registration}
            onChange={onChange}
            id="flexCheckDefault"
          />
          <label
            className="form-check-label inline-block text-gray-900 font-semibold"
            for="flexCheckDefault"
          >
            {label}
          </label>
        </div>
        {error}
        <div className="mt-2 text-sm">{caption}</div>
      </div>
    </div>
  );
};
