import React, { useEffect, useState } from 'react';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { AtSign, MapPin, Phone } from 'react-feather';
import { Button, FullScreenSpinner } from 'components/Elements';
import Bg from 'assets/breadcrumb_bg.png';
import { Link } from 'react-router-dom';
import { CheckboxField, Form, InputField, RegularTextAreaField } from 'components/Form';
import { Map } from '../components/Map';
import { useContactInfo } from '../api/getContactInfo';

export const ContactUs = () => {
  const contactInfoQuery = useContactInfo();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (contactInfoQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!contactInfoQuery.data) return null;

  return (
    <WebLayout>
      <Head title="Contact Us" description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">Contact Us</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-3xl mb-3">Contact Us</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="mx-4 md:mx-48 my-3 mb-8 grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-[#F9F9F9] p-8 flex flex-row items-center ">
          <MapPin className="text-gray-600" size={60} />
          <div className="mx-3">{contactInfoQuery.data?.location}</div>
        </div>
        <div className="bg-[#F9F9F9] p-8 flex flex-row items-center ">
          <Phone className="text-gray-600" size={60} />
          <div className="mx-3 flex flex-col">
            {contactInfoQuery.data?.phone_numbers?.map((phone) => (
              <>{phone}</>
            ))}
          </div>
        </div>
        <div className="bg-[#F9F9F9] p-8 flex flex-row items-center ">
          <AtSign className="text-gray-600" size={60} />
          <div className="mx-3">{contactInfoQuery.data?.email_address}</div>
        </div>
      </div>
      <div className="mb-4">
        <div className="text-center font-semibold text-lg mb-4">Send us a message</div>
        <div className="grid grid-cols-1 md:grid-cols-3 mb-12">
          <div className=""></div>
          <div className="mx-8 md:mx-0">
            <Form
              id="send-message"
              onSubmit={async (values) => {
                // await createPostMutation.mutateAsync({ data: values });
                const mailtoLink = `mailto:${
                  contactInfoQuery.data?.email_address
                }?subject=Contact%20Form&body=Name:%20${encodeURIComponent(
                  values.name
                )}%0D%0AEmail:%20${encodeURIComponent(
                  contactInfoQuery.data?.email_address
                )}%0D%0AMessage:%20${encodeURIComponent(values.message)}`;
                window.location.href = mailtoLink;
              }}
            >
              {({ register, formState, watch, setValue }) => (
                <>
                  <InputField
                    label="Name"
                    error={formState.errors['name']}
                    registration={register('name')}
                  />
                  <InputField
                    label="Email"
                    error={formState.errors['email']}
                    registration={register('email')}
                  />
                  <InputField
                    label="Subject"
                    error={formState.errors['subject']}
                    registration={register('subject')}
                  />
                  <RegularTextAreaField
                    label="Message"
                    error={formState.errors['message']}
                    registration={register('message')}
                  />
                  <CheckboxField
                    label="Would you like to receive our monthly newsletter?"
                    caption="We won't use your email for anything else except our monthly newsletter and you can unsubscribe at anytime you want."
                  />
                  <button className="bg-[#142852] p-3 w-full px-4 uppercase text-white">
                    Send Message
                  </button>
                </>
              )}
            </Form>
          </div>
          <div className=""></div>
        </div>
      </div>
      <Map
        lng={contactInfoQuery.data?.coordinates?.lng}
        lat={contactInfoQuery.data?.coordinates?.lat}
      />
    </WebLayout>
  );
};
