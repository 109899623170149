import React from 'react';
import { NumericFormat } from 'react-number-format';

export const CurrencyInputField = ({
  label,
  value,
  onChange,
  type = 'text',
  prefix,
  suffix,
  thousandSeparator = true,
  decimalSeparator = '.',
  thousandsGroupStyle = 'thousand',
  decimalScale,
  fixedDecimalScale = false,
  allowNegative = true,
  allowEmptyFormatting = false,
  allowLeadingZeros = false,
  isNumericString = false,
  displayType = 'input',
  format,
  mask = '_',
  customInput,
  onValueChange,
  isAllowed,
  renderText,
  getInputRef,
  allowedDecimalSeparators,
  ...rest
}) => {
  const handleValueChange = (values) => {
    if (onValueChange) {
      onValueChange(values);
    }
    // If onChange is provided, call it with the numeric string value
    if (onChange) {
      onChange(values.value);
    }
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <NumericFormat
        value={value}
        onValueChange={handleValueChange}
        type={type}
        prefix={prefix}
        suffix={suffix}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        thousandsGroupStyle={thousandsGroupStyle}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        allowNegative={allowNegative}
        allowEmptyFormatting={allowEmptyFormatting}
        allowLeadingZeros={allowLeadingZeros}
        isNumericString={isNumericString}
        displayType={displayType}
        format={format}
        mask={mask}
        customInput={customInput}
        isAllowed={isAllowed}
        renderText={renderText}
        getInputRef={getInputRef}
        allowedDecimalSeparators={allowedDecimalSeparators}
        {...rest}
      />
    </div>
  );
};
