import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getContactInfo = () => {
  return axios.get('/contacts/info');
};

export const useContactInfo = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['contactInfo'],
    queryFn: () => getContactInfo(),
  });
};
