import React from 'react';
import { useMutation } from 'react-query';
import { axios } from 'lib/axios';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';

// Function to initiate payment
const initiatePayment = async ({ data }) => {
  return await axios.post('/orders/pay-now', data);
};

initiatePayment.propTypes = {
  data: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
  }).isRequired,
};

export const usePayNow = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onSuccess: (data) => {
      // Redirect to payment link
      window.location.href = data.link;
      addNotification({
        type: 'success',
        title: 'Redirecting to Payment Gateway',
      });
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        title: 'Payment Initialization Failed',
        message: error.response?.data?.message || 'Something went wrong!',
      });
    },
    ...config,
    mutationFn: initiatePayment,
  });
};
