import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getDesigns = () => {
  return axios.get('/designs');
};

export const useDesigns = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['designs'],
    queryFn: () => getDesigns(),
  });
};
