import { Navigate, Route, Routes } from 'react-router-dom';

import { Design } from './Design';
import { Designs } from './Designs';
import { Order } from './Order';
import { Pay } from './Pay';
import { ConfirmPayment } from './ConfirmPayment';

export const DesignsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Designs />} />
      <Route path=":slug" element={<Design />} />
      <Route path=":slug/order" element={<Order />} />
      <Route path=":slug/order/:orderId/pay" element={<Pay />} />
      <Route path=":slug/order/:orderId/pay/confirm-payment" element={<ConfirmPayment />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
