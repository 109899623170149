import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getSocials = () => {
  return axios.get('/socials');
};

export const useSocials = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['socials'],
    queryFn: () => getSocials(),
  });
};
