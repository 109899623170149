import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getDesign = ({ slug }) => {
  return axios.get(`/designs/${slug}`);
};

export const useDesign = ({ slug, config }) => {
  return useQuery({
    ...config,
    queryKey: ['design', slug],
    queryFn: () => getDesign({ slug }),
  });
};
