import { Button } from 'components/Elements';
import { Form, InputField, LocationField } from 'components/Form';
import { useAuth } from 'lib/auth';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';

const schema = z.object({
  name: z.string().nonempty({ message: 'Name is required' }),
  username: z.string().nonempty({ message: 'Username is required' }),
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

export const RegisterForm = ({ onSuccess }) => {
  const { register, isRegistering } = useAuth();

  // const [phoneNumber, setPhoneNumber] = useState('');

  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          await register(values);
          onSuccess();
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="text"
              label="Name"
              error={formState.errors['name']}
              registration={register('name')}
            />
            <InputField
              type="text"
              label="Username"
              error={formState.errors['username']}
              registration={register('username')}
            />
            {/* 
            <div className="">
              <div className={' text-sm font-medium text-gray-700 dark:text-gray-400 mb-2'}>
                Phone Number
              </div>
              <PhoneInput
                country={'ug'}
                value={phoneNumber}
                inputStyle={{
                  width: '100%',
                  height: '50px',
                  fontSize: '1rem',
                  border: '1px solid #d1d5db',
                  borderRadius: '8px',
                }}
                onChange={(phone) => setPhoneNumber(`+${phone}`)}
              />
            </div> */}
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              passwordField={true}
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div className="">
              By signing up, you are agreeing to our{' '}
              <Link to="/terms-and-conditions" className="text-[#142852] underline">
                Terms and conditions
              </Link>
              . This site uses essential cookies. See our Cookie Notice for more information.
            </div>

            <div>
              <Button isLoading={isRegistering} type="submit" className="w-full">
                Sign Up
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          Already have an account? {''}
          <Link to="../login" className="font-medium text-[#142852] underline hover:text-blue-500">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
