import React from 'react';
import TeamMateImg from 'assets/team/teammate.png';
import { Mail } from 'react-feather';
import { useTeam } from '../api/getTeam';
import { Spinner } from 'components/Elements';
import { UPLOADS_URL } from 'config';

export const Team = () => {
  const teamQuery = useTeam();

  if (teamQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!teamQuery.data) return null;
  return (
    <div className="bg-[#142852] ml-4 md:ml-12 px-2 md:px-24 py-12">
      <div className="mx-4 md:mx-24 header mb-3 flex items-center">
        <div className="text-4xl text-white font-semibold">Our Team</div>
        <div className="flex items-center ml-4 md:ml-8">
          <div className="bg-white rounded-full h-1 w-1 md:h-4 md:w-4"></div>
          <div className="bg-white rounded-full h-2 w-2 md:h-8 md:w-8 ml-2 md:ml-4"></div>
        </div>
      </div>
      <div className="text-white md:w-3/4 mx-4 md:mx-24 mb-8">
        Lorem ipsum dolor sit amet consectetur. Suspendisse amet amet facilisis malesuada. Amet nibh
        sed sed blandit sollicitudin habitasse diam a.
      </div>
      <div className="mx-4 md:mx-24 grid grid-cols-1 gap-8 md:gap-24 md:grid-cols-2">
        {teamQuery.data?.map((teammate, index) => (
          <TeamMate
            name={teammate.name}
            role={teammate.role}
            img={UPLOADS_URL + '/avatars/' + teammate.avatar}
          />
        ))}
      </div>
    </div>
  );
};

const TeamMate = ({ name, role, img }) => {
  return (
    <div className="bg-[#1C3260] p-4">
      <img src={img} alt={name} className="w-full h-96 object-cover mb-8" />
      <div className="text-center text-white font-semibold">{name}</div>
      <div className="text-center text-white mb-8">{role}</div>
      <div className="flex flex-row items-center justify-center mb-4 space-x-2">
        <a href="#" className="text-white">
          <Mail />
        </a>
      </div>
    </div>
  );
};
