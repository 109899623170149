import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link, useParams } from 'react-router-dom';
import { useDesign } from '../api/getDesign';
import { UPLOADS_URL } from 'config';
import { useEffect } from 'react';
import { Button } from 'components/Elements';
import { Check } from 'react-feather';
import { usePayNow } from '../api/initiatePayment';

export const Pay = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { slug, orderId } = useParams();

  const designQuery = useDesign({ slug });

  const initiatePaymentMutation = usePayNow();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      <Head title={designQuery.data?.title} description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48 text-xs md:text-sm">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <Link to="/designs">Designs</Link>
          <span className="mx-2">/</span>
          <Link to={`/designs/${designQuery.data?.slug}`}>{designQuery.data?.title}</Link>
          <span className="mx-2">/</span>
          <span className="">Order Now</span>
          <span className="mx-2">/</span>
          <span className="font-bold">Pay</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-center text-3xl mb-3">Pay Now</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="mx-8 md:mx-48 mb-4">
        <div className="font-semibold text-lg mb-4">Order Summary</div>

        <div className="bg-[#FBFBFB] p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <img
              src={UPLOADS_URL + '/designs/' + designQuery.data?.images[0]}
              alt="design"
              className="w-full h-80 object-cover"
            />
            <div className="">
              <div className="font-semibold text-lg mb-4">{designQuery.data?.title}</div>
              <div className=" text-lg mb-4">{designQuery.data?.description}</div>
              <div className=" text-lg mb-4">Price: UGX {designQuery.data?.price}</div>
              <div className="">Customization Options</div>
              <div className="text-sm">
                <ul>
                  {designQuery.data?.customization_options?.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-8 md:mx-48 mb-4">
        <div className="font-semibold text-lg mb-4">Billing Information</div>
        <div className="bg-[#FBFBFB] p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <p className="mt-1 text-sm text-gray-900">{user?.name}</p>
            </div>
            {user?.address?.street && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Street</label>
                <p className="mt-1 text-sm text-gray-900">{user?.address?.street}</p>
              </div>
            )}
            {user?.address?.city && (
              <div>
                <label className="block text-sm font-medium text-gray-700">City</label>
                <p className="mt-1 text-sm text-gray-900">{user?.address?.city}</p>
              </div>
            )}
            {user?.address?.state && (
              <div>
                <label className="block text-sm font-medium text-gray-700">State</label>
                <p className="mt-1 text-sm text-gray-900">{user?.address?.state}</p>
              </div>
            )}
            <div>
              <label className="block text-sm font-medium text-gray-700">Country</label>
              <p className="mt-1 text-sm text-gray-900">{user?.address?.country}</p>
            </div>
            {user?.address?.postalCode && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Postal Code</label>
                <p className="mt-1 text-sm text-gray-900">{user?.address?.postalCode}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-8 md:mx-48 mb-4">
        <div className="font-semibold text-lg mb-4">Contact Information</div>
        <div className="bg-[#FBFBFB] p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <p className="mt-1 text-sm text-gray-900">{user?.phoneNumber}</p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email Address</label>
              <p className="mt-1 text-sm text-gray-900">{user?.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 md:mx-48 mb-8">
        <div className="flex flex-row justify-end mb-8">
          <Button
            startIcon={<Check />}
            onClick={async () => {
              await initiatePaymentMutation.mutateAsync({
                data: {
                  orderId: orderId,
                },
              });
            }}
            isLoading={initiatePaymentMutation.isLoading}
          >
            Pay Now
          </Button>
        </div>
      </div>
    </WebLayout>
  );
};
