import React from 'react';
import { Spinner } from './Spinner';

export const FullScreenSpinner = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Spinner size="xl" />
    </div>
  );
};
