import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getFeedbacks = () => {
  return axios.get('/feedbacks');
};

export const useFeedbacks = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['feedbacks'],
    queryFn: () => getFeedbacks(),
  });
};
