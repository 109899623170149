import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useFeedbacks } from '../api/getFeedbacks';
import { FullScreenSpinner } from 'components/Elements';

export const Feedback = () => {
  const feedbacksQuery = useFeedbacks();

  if (feedbacksQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!feedbacksQuery.data) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '40px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
        },
      },
    ],
  };

  return (
    <div className="bg-white py-12 mx-8 md:mx-24">
      {feedbacksQuery.data?.length < 0 && (
        <div className="header mb-3 flex items-center">
          <div className="text-4xl font-semibold">Feedback From Clients</div>

          <div className="flex items-center ml-4 md:ml-8">
            <div className="bg-black rounded-full h-1 w-1 md:h-4 md:w-4"></div>
            <div className="bg-black rounded-full h-2 w-2 md:h-8 md:w-8 ml-2 md:ml-4"></div>
          </div>
        </div>
      )}
      <Slider {...settings}>
        {feedbacksQuery.data
          ?.filter((feedback) => feedback.active === true)
          .map((feedback, index) => (
            <FeedbackCard name={feedback.name} feedback={feedback.message} title={feedback.title} />
          ))}
      </Slider>
    </div>
  );
};

const FeedbackCard = ({ name, title, feedback }) => {
  const getInitial = (name) => name.charAt(0).toUpperCase();

  return (
    <div className="bg-white shadow-lg rounded-2xl p-12 m-4">
      <div className="mb-3">{feedback}</div>
      <div className="flex flex-row space-x-2 items-center">
        <div className="rounded-full bg-gray-300 flex items-center justify-center w-12 h-12">
          <span className="text-lg font-semibold text-white">{getInitial(name)}</span>
        </div>
        <div>
          <div className="font-semibold">{name}</div>
          <div className="text-sm text-gray-500">{title}</div>
        </div>
      </div>
    </div>
  );
};
