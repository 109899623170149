import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link } from 'react-router-dom';
import { useDesigns } from '../api/getDesigns';
import { Spinner } from 'components/Elements';
import { UPLOADS_URL } from 'config';

export const Designs = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const designsQuery = useDesigns();

  if (designsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!designsQuery.data) return null;

  return (
    <WebLayout>
      <Head title="Designs" description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">Designs</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-3xl mb-3">Designs</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className=" mx-4 md:mx-48 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {designsQuery.data?.map((design, index) => (
            <DesignCard
              key={index}
              image={UPLOADS_URL + '/designs/' + design.images[0]}
              name={design.title}
              slug={design.slug}
            />
          ))}
        </div>
      </div>
    </WebLayout>
  );
};

const DesignCard = ({ image, name, slug }) => {
  return (
    <div className="p-4 bg-white rounded-xl shadow-lg flex flex-col justify-between">
      <Link
        to={`/designs/${slug}`}
        className="relative w-full h-[60vh] mb-4 overflow-hidden rounded-md"
      >
        <img src={image} alt={name} className="object-cover w-full h-full" />
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
      </Link>

      <div className="text-center text-lg font-semibold">{name}</div>
    </div>
  );
};
