import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export const Careers = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <WebLayout>
      <Head title="Careers" description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">Careers</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-3xl mb-3">Careers</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="flex flex-col items-center justify-center mb-12 px-4 text-center">
        <p className="text-lg mb-4">
          Thank you for your interest in working with Verdens. Currently, we do not have any job
          openings.
        </p>
        <p className="text-lg mb-4">
          Please check back later or send your resume to{' '}
          <a href="mailto:careers@verdens.com" className="text-blue-600">
            careers@verdens.com
          </a>
          for future opportunities.
        </p>
      </div>
    </WebLayout>
  );
};
