import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, InputField } from 'components/Form';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as z from 'zod';

import { useUpdateShippingDetails } from '../api/updateShippingDetails';
import { useAuth } from 'lib/auth';
import { Check } from 'react-feather';
import { useDesign } from '../api/getDesign';
import { useCreateOrder } from '../api/createOrder';
import { useNavigate, useParams } from 'react-router-dom';

export const UpdateShippingDetailsForm = () => {
  const { user } = useAuth();
  const updateShippingDetailsMutation = useUpdateShippingDetails();

  const { slug } = useParams();

  const designQuery = useDesign({ slug });

  const navigate = useNavigate();

  const createOrderMutation = useCreateOrder();
  // name, city, country, email address, phone number are required
  const schema = z.object({
    name: z.string().nonempty('Name is required'),
    country: z.string().nonempty('Country is required'),
    phoneNumber: z.string().nonempty('Phone Number is required'),
    email: z.string().nonempty('Email is required'),
  });

  if (updateShippingDetailsMutation.isLoading) return <FullScreenSpinner />;

  return (
    <div>
      <Form
        id="update-details"
        onSubmit={async (values) => {
          const result = await updateShippingDetailsMutation.mutateAsync({
            data: {
              address: {
                street: values.street,
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.postalCode,
              },
              ...values,
            },
          });
          if (result) {
            const result2 = await createOrderMutation.mutateAsync({
              data: {
                designId: designQuery.data._id,
                amount: parseInt(designQuery.data.price),
              },
            });
            if (result2) {
              navigate(`/designs/${slug}/order/${result2._id}/pay`);
            }
          }
        }}
        options={{
          defaultValues: {
            name: user?.name,
            street: user?.address?.street,
            city: user?.address?.city,
            state: user?.address?.state,
            country: user?.address?.country,
            postalCode: user?.address?.postalCode,
            phoneNumber: user?.phoneNumber,
            email: user?.email,
          },
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <div className="mx-8 md:mx-48 mb-4">
              <div className="font-semibold text-lg mb-4">Billing Information</div>
              <div className="bg-[#FBFBFB] p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputField
                    label="Name"
                    error={formState.errors['name']}
                    registration={register('name')}
                  />
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                  <InputField
                    label="State"
                    error={formState.errors['state']}
                    registration={register('state')}
                  />
                  <InputField
                    label="Country"
                    error={formState.errors['country']}
                    registration={register('country')}
                  />
                  <InputField
                    label="Postal Code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
              </div>
            </div>
            <div className="mx-8 md:mx-48 mb-4">
              <div className="font-semibold text-lg mb-4">Contact Information</div>
              <div className="bg-[#FBFBFB] p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputField
                    label="Phone"
                    error={formState.errors['phoneNumber']}
                    registration={register('phoneNumber')}
                  />
                  <InputField
                    label="Email Address"
                    error={formState.errors['email']}
                    registration={register('email')}
                  />
                </div>
              </div>
            </div>
            <div className="mx-4 md:mx-48 mb-8">
              <div className="flex flex-row justify-end mb-8">
                <Button type="submit" startIcon={<Check />}>
                  Order Now
                </Button>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

UpdateShippingDetailsForm.propTypes = {
  id: PropTypes.string.isRequired,
};
