import { Button, Dialog, Spinner } from 'components/Elements';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VerdenBotImg from 'assets/verdenbot.svg';

export function RequestForPrice({ price, slug }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showPrice, setShowPrice] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setLoading(false);
    setShowWelcome(false);
    setShowPrice(false);
  };

  const handleOpen = () => {
    setShow(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setShowWelcome(true);
      setTimeout(() => {
        setShowPrice(true);
      }, 1000);
    }, 1000);
  };

  return (
    <div>
      <Button onClick={handleOpen}>Request for Price</Button>
      <Dialog isOpen={show} onClose={handleClose} initialFocus={null}>
        <div className="inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="text-lg mb-4 border-b pb-3 flex flex-row items-center space-x-2">
            <img src={VerdenBotImg} alt="Verden Bot" className="w-10 h-10" />
            <span className="font-semibold">Verdens Bot</span>
          </div>
          {loading && (
            <div className="flex justify-center items-center">
              <Spinner />
            </div>
          )}
          {showWelcome && (
            <div className="mb-4 flex flex-row items-start space-x-2">
              <img src={VerdenBotImg} alt="Verden Bot" className="w-8 h-8" />
              <div className="bg-gray-100 rounded-2xl p-3 shadow-sm">
                <p className="text-gray-600">
                  Hello there! I am Verdens Bot, I'm currently fetching the price for you...
                </p>
                <span className="text-xs text-gray-400">1 second ago</span>
              </div>
            </div>
          )}
          {showPrice && (
            <div className="mb-4">
              <div className="mb-4 flex flex-row items-start space-x-2">
                <img src={VerdenBotImg} alt="Verden Bot" className="w-8 h-8" />
                <div className="bg-gray-100 rounded-2xl p-3 shadow-sm">
                  <p className="text-gray-600">
                    The Price is UGX {parseInt(price)?.toLocaleString()}
                  </p>
                  <span className="text-xs text-gray-400">Just now</span>
                </div>
              </div>
              <div className="mb-4 flex flex-row items-start space-x-2">
                <img src={VerdenBotImg} alt="Verden Bot" className="w-8 h-8" />
                <div className="mt-2 flex flex-col space-y-2">
                  <Button
                    onClick={() => navigate(`/designs/${slug}/order`)}
                    className="rounded-full"
                  >
                    Place an order?
                  </Button>
                  <Button onClick={handleClose} variant="inverse" className="rounded-full">
                    Close Chat
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}
